import { ReactNode } from 'react';
import { NavLogos } from './nav-logos';
import { NavAppsMenu } from './nav-apps-menu';
import { getNavAppConfig } from './nav.helpers';
import { NavPlatformMenu } from './nav-platform-menu';
import { App } from '../../../common/types/app.types';
import { NavAppAdminsMenu } from './nav-app-admins-menu';

import { useRecoilValue } from 'recoil';
import { AppLogo } from '../app-logo/app-logo';
import { NavBackIconLink } from './nav-back-icon-link';
import { classNames } from '../../helpers/classNames';
import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { checkUserHasAnyRole } from '../../../common/helpers/auth.helpers';
import { ADMIN_ROLE } from '../../../common/types/roles-permissions.types';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';

interface Props {
  app: App;
  appLinks?: ReactNode;
  isPlatform?: boolean;
  showBackIcon?: boolean;
  hideAppsIcon?: boolean;
  customLogoUrl?: string;
  customLogo?: string | ReactNode;
}

export const Nav = ({
  app,
  appLinks,
  customLogo,
  customLogoUrl,
  isPlatform = true,
  hideAppsIcon = false,
  showBackIcon = false,
}: Props) => {
  const currentUser = useRecoilValue(CurrentUserAtom);
  const { logo, appName, url, appColor } = getNavAppConfig(app);

  const config = getMultiClientPlatformConfigForClient();
  const isUserAdmin = currentUser && checkUserHasAnyRole(currentUser, [ADMIN_ROLE]);
  const filteredRoles = currentUser?.rolesAndPermissions?.filter((role) => !!role.roleId) || [];
  const hideAppsIcons = config.topNav?.hideAppsIcon || hideAppsIcon || (filteredRoles.length || 0) <= 0;

  return (
    <div
      className={classNames(
        'nav-bg fixed top-0 z-40 flex h-[60px] w-full items-center border-b border-gray-300 shadow',
        config.hideMainBarAppIcons ? 'pl-4 pr-8' : 'px-8',
      )}
    >
      {hideAppsIcons ? undefined : <NavAppsMenu />}
      {showBackIcon && <NavBackIconLink className={hideAppsIcons ? undefined : 'ml-2'} />}
      <NavLogos
        url={url}
        isPlatform={isPlatform}
        customLogo={customLogo}
        customLogoUrl={customLogoUrl}
        logo={
          <AppLogo icon={logo} color={appColor}>
            {appName}
          </AppLogo>
        }
      />
      <div className="flex flex-grow items-center justify-end">
        {appName && appLinks && isUserAdmin ? <NavAppAdminsMenu appName={appName} links={appLinks} /> : undefined}
        <NavPlatformMenu />
      </div>
    </div>
  );
};
